import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GrowingInfantLogo from '../assets/growing_infant_logo.png';
import { loadStripe } from '@stripe/stripe-js';
import { GetUserId } from '../services/AuthService';
import { GetUserWithId } from '../services/UserService';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const priceId = process.env.REACT_APP_STRIPE_PRICE_ID
const successUrl = process.env.REACT_APP_STRIPE_CHECKOUT_SUCCESS_URL
const cancelUrl = process.env.REACT_APP_STRIPE_CHECKOUT_CANCEL_URL
const stripePromise = loadStripe(publishableKey);

const commonStyles = {
    card: {
        width: '400px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 1,
        bgcolor: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        p: 4,
    },
    button: {
        primary: {
            bgcolor: '#4caf50',
            color: 'white',
            '&:hover': {
                bgcolor: '#43a047'
            },
            textTransform: 'none',
            py: 2,
            borderRadius: '8px',
        }
    }
};

const Checkout = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('success')) {
            navigate('/dashboard', { replace: true });
        } else {
            handleCheckout();
        }
    }, [navigate]);

    const handleCheckout = async () => {
        setIsLoading(true);
        setError(null);

        const userId = GetUserId();
        const user = await GetUserWithId(userId);

        try {
            const stripe = await stripePromise;
            if (!stripe) {
                throw new Error('Stripe failed to initialize');
            }

            const { error } = await stripe.redirectToCheckout({
                lineItems: [{
                    price: priceId,
                    quantity: 1,
                }],
                mode: 'payment',
                successUrl: successUrl,
                cancelUrl: cancelUrl,
                customerEmail: user.email !== null ? user.email : '',
            });

            if (error) {
                setError(error.message);
            }
        } catch (err) {
            console.error(err);
            setError('Failed to initialize checkout. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            gap: 2
        }}>
            <CircularProgress />
            <Typography>
                Initializing checkout...
            </Typography>
        </Box>
    );
};

export default Checkout;