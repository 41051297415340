import React from "react";
import { AppBar, Toolbar, Typography, Button, Container, Grid, Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  heroSection: {
    backgroundColor: "#f5f5f5",
    padding: "50px 0",
    textAlign: "center",
  },
  featureSection: {
    padding: "50px 0",
  },
  featureCard: {
    minHeight: "200px",
  },
  footer: {
    backgroundColor: "#2e3b4e",
    color: "#fff",
    padding: "20px 0",
    textAlign: "center",
  },
}));

const LandingPage = () => {
  const classes = useStyles();

  return (
    <>
      {/* Header */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Growing Infant
          </Typography>
          <Button color="inherit">Login</Button>
          <Button color="inherit">Sign Up</Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <div className={classes.heroSection}>
        <Container>
          <Typography variant="h2" gutterBottom>
            Track Your Child's Growth Journey with Confidence
          </Typography>
          <Typography variant="h6" gutterBottom>
            Professional-grade growth tracking made simple for parents. Monitor your child's development with accurate measurements and visual insights.
          </Typography>
          <Button variant="contained" color="primary" size="large">
            Get Started Free
          </Button>
        </Container>
      </div>

      {/* Features Section */}
      <div className={classes.featureSection}>
        <Container>
          <Typography variant="h4" gutterBottom align="center">
            Everything You Need to Track Growth
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card className={classes.featureCard}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Accurate Tracking
                  </Typography>
                  <Typography>
                    Track your child's growth with professional-grade accuracy and detailed measurements.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={classes.featureCard}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Visual Insights
                  </Typography>
                  <Typography>
                    Clear, visual charts and percentile tracking to understand your child's growth journey.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={classes.featureCard}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Quick & Easy
                  </Typography>
                  <Typography>
                    Save time with our intuitive interface. Record and access measurements in seconds.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Call to Action */}
      <div className={classes.heroSection}>
        <Container>
          <Typography variant="h4" gutterBottom>
            Start Tracking Your Child's Growth Today
          </Typography>
          <Button variant="contained" color="secondary" size="large">
            Join Now
          </Button>
        </Container>
      </div>

      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="body2">© 2024 Growing Infant. All rights reserved.</Typography>
      </footer>
    </>
  );
};

export default LandingPage;
