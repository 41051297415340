import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react';
import Dashboard from './pages/Dashboard';
import Auth from './pages/Auth';
import Landing from './pages/Landing';
import PpcLanding from './pages/PpcLanding';
import LegalPages from './pages/LegalPages';
import Checkout from './pages/Checkout';
import PaymentSuccess from './pages/PaymentSuccess';
import RakutenTestPage from './pages/RakutenTest';
import './App.css';
import { GetUserId, IsLoggedIn } from './services/AuthService';
import { IsPaidUser } from './services/UserService';
import { initGA, trackPageView, setUser } from './services/AnalyticsService';

const GA_MEASUREMENT_ID = 'G-05X4VQ9JYE'; 

function App() {
  // Initialize Google Analytics
  useEffect(() => {
    initGA(GA_MEASUREMENT_ID);
  }, []);

  const RouteGuard = ({ requiresAuth = true }) => {
    const isLoggedIn = IsLoggedIn();
    const [isPaidUser, setIsPaidUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    // Track page views on route change
    useEffect(() => {
      if (!isLoading) {
        trackPageView(location.pathname);
      }
    }, [location, isLoading]);

    useEffect(() => {
      const checkPaidStatus = async () => {
        if (isLoggedIn) {
          const userId = GetUserId();
          // Set user ID for GA
          setUser(userId);
          
          const paidStatus = await IsPaidUser(userId);
          if (paidStatus === true) {
            setIsPaidUser(true);
          } else {
            const justPaid = sessionStorage.getItem('paid_user');
            if (justPaid === 'true') {
              setIsPaidUser(true);
            } else {
              setIsPaidUser(false);
            }
          }
        }
        setIsLoading(false);
      };
      checkPaidStatus();
    }, [isLoggedIn, isPaidUser]);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    // Redirect logic for auth page
    if (location.pathname === '/auth') {
      if (isLoggedIn && isPaidUser) {
        return <Navigate to="/dashboard" replace />;
      }
      if (isLoggedIn && !isPaidUser) {
        return <Navigate to="/checkout" replace />;
      }
      return <Outlet />;
    }

    // Handle protected routes
    if (requiresAuth) {
      if (!isLoggedIn) {
        return <Navigate to="/auth" replace />;
      }
      if (isPaidUser) {
        if (location.pathname !== '/dashboard') {
          return <Navigate to="/dashboard" replace />;
        }
      }
    }
    return <Outlet />;
  };

  return (
    <>
      <Helmet>
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <meta
          http-equiv="Content-Security-Policy"
          content="script-src 'self' 'unsafe-inline' 'unsafe-eval' https://accounts.google.com https://www.gstatic.com https://js.stytch.com https://www.google-analytics.com https://www.googletagmanager.com https://tag.unifyintent.com https://heapanalytics.com https://js.stripe.com"
        />
      </Helmet>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Landing />} />
          <Route path="/privacy" element={<LegalPages />} />
          <Route path="/terms" element={<LegalPages />} />
          <Route path="/rakutentest123" element={<RakutenTestPage />} />
          {/* Auth and Protected routes */}
          <Route element={<RouteGuard />}>
            <Route path="/auth" element={<Auth />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;